<template>
    <div v-if="isReady" class="fill-height">
        <!-- Error -->
        <div
            v-if="error || !isAuthorized"
            class="fill-height d-flex flex-column justify-center align-center"
        >
            <v-alert type="error">
                {{ error || 'Для доступа к ЦОК необходима авторизация.' }}
            </v-alert>
            <!-- <external-login-btn @error="onExternalLoginError" /> -->
        </div>
        <!-- END Error -->
        <!-- View -->
        <iframe
            v-else
            src="/static/start/index.html"
            scrolling="no" 
            frameborder="no"
            class="vw-100 vh-100"
            @load="onFrameLoaded"
        ></iframe>
       <!-- END View -->
    </div>
</template>

<script>
// import ExternalLoginBtn from '@/components/other/ExternalLoginBtn.vue'
import IframeConnectionMixin from '@/mixins/IframeConnectionMixin.vue'

export default {
    name: 'DigitalContentStartView',
    mixins: [IframeConnectionMixin],
    // components: {
    //     ExternalLoginBtn
    // },
    data () {
        return {
            error: null,
            isReady: true
        }
    },
    computed: {
        isAuthorized () {
            return this.$store.getters['user/isAuthorized'];
        }
    },
    async created () {
        if (!this.$store.state.user.access_token) { return }
        this.isReady = false;

        if (!this.$store.getters['user/isSchool'] && !this.$store.getters['user/isAdmin']) {
            
            const res = await this.$store.dispatch('app/ssoGetProfile', {
                access_token: this.$store.state.user.access_token
            });
            if (res.error || res.data?.error) {
                await this.$store.dispatch('user/logout');
                location.reload();
                return;
            }
        }
        this.isReady = true;
    },
    methods: {
        onExternalLoginError (err) {
            this.error = err
        }
    }
}
</script>